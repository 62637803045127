<script>
export default {
  data() {
    return {
      footerProps: {
        itemsPerPageOptions: [10, 30, 50]
      }
    };
  }
};
</script>
